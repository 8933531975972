import styles from './index.module.less';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div>
        XenosTian© {new Date().getFullYear()}{' '}
        <a
          className={styles.record}
          href='https://beian.miit.gov.cn'
          target='_blank'
          rel='noreferrer'>
          赣ICP备2024020072号
        </a>
      </div>
    </footer>
  );
};

export default Footer;
