import { useMemo, useState } from 'react';
import { GlobalContext } from './providers';
import { Router } from './routes';
import Footer from './components/Footer';
import Header from './components/Header';

export const App = () => {
  const [gLoading, setGLoading] = useState(false);
  const globalContextValue = useMemo(
    () => ({ gLoading, setGLoading }),
    [gLoading],
  );

  return (
    <GlobalContext.Provider value={globalContextValue}>
      <Header />
      <Router />
      <Footer />
    </GlobalContext.Provider>
  );
};
