import { useLocation, useOutlet } from 'react-router-dom';
import { routes } from '@/routes';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import './index.less'

export const Container = () => {
  const location = useLocation();
  const currentOutlet = useOutlet();
  const { nodeRef } =
    routes.find((route) => route.path === location.pathname) ?? {};
  return (
    <div className='container'>
      <SwitchTransition>
        <CSSTransition
          key={location.pathname}
          nodeRef={nodeRef}
          timeout={300}
          classNames='page'
          unmountOnExit>
          {() => (
            <div ref={nodeRef} className='page'>
              {currentOutlet}
            </div>
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};
