import styles from './index.module.less';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useContext, useEffect } from 'react';
import { GlobalContext } from '@/providers';
import logo from '@assets/哆啦A梦.webp';

const Header = () => {
  const { gLoading } = useContext(GlobalContext);

  useEffect(() => console.log('gLoading', gLoading), [gLoading]);

  return (
    <header className={styles.header}>
      <div className={styles.info}>
        <img className={styles.logo} src={logo} alt='logo' />
        <span className={styles.title}>XenosTian</span>
      </div>
      <div className={styles.loadingIcon}>
        <Spin indicator={<LoadingOutlined />} spinning={gLoading} />
      </div>
    </header>
  );
};

export default Header;
