import { Container } from '@/pages';
import { lazy, Suspense, useMemo, createRef } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';

const App = lazy(() => import('@pages/App'));
const Nothing = lazy(() => import('@pages/Nothing'));

const routes = [
  {
    path: '/',
    element: <Navigate to='/pages' replace />,
  },
  {
    path: '/pages',
    element: <Container />,
    children: [
      {
        path: '',
        index: true,
        element: <Navigate to='home' replace relative />,
      },
      { path: 'home', element: <App />, nodeRef: createRef() },
      { path: 'nothing', element: <Nothing />, nodeRef: createRef() },
    ],
  },
  { path: '*', element: <Navigate to='/pages' replace /> },
];

const generateRoutes = (current) => {
  const processed = current.map((item) => {
    const tmp = {
      ...item,
    };
    if (item.element) {
      tmp.element = <Suspense>{item.element}</Suspense>;
    }
    if (item.children) {
      tmp.children = generateRoutes(item.children);
    }
    return tmp;
  });
  return processed;
};

export const Router = () => {
  return useRoutes(useMemo(() => generateRoutes(routes), []));
};

export { routes };
